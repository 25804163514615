export const initialState = () => ({
  avilableBranch: {},
  fetchAvilableBranchLoading: false,
  fetchAvilableBranchFailure: false,
  fetchAvilableBranchSuccess: true,

  branchDeliveryArea: [],
  fetchBranchDeliveryAreaLoading: false,
  fetchBranchDeliveryAreaFailure: false,
  fetchBranchDeliveryAreaSuccess: true,

  branchList: [],
  fetchBranchListLoading: false,
  fetchBranchListFailure: false,
  fetchBranchListSuccess: true,

  activeBranch: {},

  changeBranchLoading: false,
  changeBranchFailure: false,
  changeBranchSuccess: true,
});

import { View, Image, Text } from "../";
import { Price } from "../Price";
import { config } from "../../../utils/apiConfig";
import { UpdateCartButton } from "../UpdateCartButton";
import { Localize, normalizeStringToNumebr } from "../../../utils/common";
import { measurementEnum } from "../../../utils/enum";

export default function HCart({ product, index, isFree = false }) {
  return (
    <>
      {isFree && <Text>Free Product</Text>}
      <View className="cart-item-wrapper">
        <View className="cart-item" key={index}>
          <View className="cart-image-wrapper">
            <Image source={product?.image ?? product?.product?.image} />
          </View>
          <View className="item-detail-wrapper">
            <View className="title-wrapper">
              <View className="title">
                {Localize(product?.name ?? product?.product?.name)}
              </View>
              {!isFree && <Price price={product?.itemTotalPrice} />}
            </View>
            <View className="item-controller">
              <View>
                {!isFree && (
                  <Price price={product?.salePrice || product?.price} />
                )}
                <View className="qty">
                  {normalizeStringToNumebr(
                    product?.weight ?? product?.productMeasurement?.weight
                  )}{" "}
                  {
                    measurementEnum[
                      product?.measurement ??
                        product?.productMeasurement?.measurement
                    ]
                  }
                </View>
              </View>
              {!isFree && (
                <UpdateCartButton cartItem={product} measurement={product} />
              )}
            </View>
          </View>
        </View>
        {/* <View className='cart-price-status'>
                <View className={product.priceStatus}>
                    {`${Localize(product.name)}'s price has been ${product.priceStatus === "incr" ? "increased" : "decreased"} by`} <Price price={product.priceDiff} isSplit={false} />
                </View>
            </View> */}
      </View>
    </>
  );
}

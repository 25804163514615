export const initialState = () => ({
    allCategories: [],
    fetchAllCategoriesPending: false,
    fetchAllCategoriesFailure: false,
    fetchAllCategoriesSuccess: true,
    top10Categories: [],
    fetchTop10CategoriesPending: false,
    fetchTop10CategoriesFailure: false,
    fetchTop10CategoriesSuccess: true,
});

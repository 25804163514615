

export const paymentMethodEnum = {
    "pod": "Pay on Delivery",
    "Online": "Online",
}

export const measurementEnum = {
    piece: "pc",
    kilogram: "kg",
    gram: "gm",
    pound: "lb",
    liter: "L",
    gallon: "gal",
    dozen: "dz",
    bunch: "bch",
    packet: "pkt",
    container: "ctr",
    can: "can",
    bottle: "btl"
}


export const OrderStatusEnum = {
    "placed": "Placed",
    "scheduled": "Confirmed",
    "processing": "Processing",
    "readyForDelivery": "ReadyForDelivery",
    "pickup": "Pickup",
    "outForDelivery": "OutForDelivery",
    "delivered": "Delivered",
    "cancelled": "Cancelled",
    "refunded": "Refunded",
    "onHold": "OnHold",
    "returnProcessing": "ReturnProcessing",
    "returned": "Returned",
    "refundProcessing": "RefundProcessing",
    "return/Refund": "Return/Refund",
    "problemWithOrder": "Problem With Order"
}

export const OrderStatusHistoryEnum = {
    "placed": "Placed",
    "processing": "Processing",
    "delivered": "Delivered",
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-container {
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.xxs {
  height: 8px;
  width: 8px;
}

.xs {
  height: 10px;
  width: 10px;
}

.s {
  height: 12px;
  width: 12px;
}

.md {
  height: 16px;
  width: 16px;
}

.l {
  height: 18px;
  width: 18px;
}

.xl {
  height: 20px;
  width: 20px;
}

.xxl {
  height: 22px;
  width: 22px;
}

.xxxl {
  height: 24px;
  width: 24px;
}

.wl {
  height: 32px;
  width: 32px;
}

.trandform180 {
  transform-rotate: "180deg";
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/Icon/Style.scss"],"names":[],"mappings":"AAOA;EACI,eAAA;EACA,aAAA;EACA,mBAAA;AANJ;;AAQA;EACI,WAAA;EACA,UAAA;AALJ;;AAOA;EACI,YAAA;EACA,WAAA;AAJJ;;AAMA;EACI,YAAA;EACA,WAAA;AAHJ;;AAKA;EACI,YAAA;EACA,WAAA;AAFJ;;AAIA;EACI,YAAA;EACA,WAAA;AADJ;;AAGA;EACI,YAAA;EACA,WAAA;AAAJ;;AAEA;EACI,YAAA;EACA,WAAA;AACJ;;AACA;EACI,YAAA;EACA,WAAA;AAEJ;;AAAA;EACI,YAAA;EACA,WAAA;AAGJ;;AADA;EACiB,0BAAA;AAIjB","sourcesContent":["// .tintColor {\n//     filter: saturate(3);\n// }\n\n// .tintHover:hover {\n//     filter: saturate(3);\n// }\n.icon-container {\n    padding:0 10px;\n    display: flex;\n    align-items: center;\n}\n.xxs {\n    height: 8px;\n    width: 8px;\n}\n.xs {\n    height: 10px;\n    width: 10px;\n}\n.s {\n    height: 12px;\n    width: 12px;\n}\n.md {\n    height: 16px;\n    width: 16px;\n}\n.l {\n    height: 18px;\n    width: 18px;\n}\n.xl {\n    height: 20px;\n    width: 20px;\n}\n.xxl {\n    height: 22px;\n    width: 22px;\n}\n.xxxl {\n    height: 24px;\n    width: 24px;\n}\n.wl {\n    height: 32px;\n    width: 32px;\n}\n.trandform180 {\n    transform: { rotate: '180deg' }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

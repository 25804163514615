import { createContext } from "react";
import English from './consts/English';
import Hindi from './consts/Hindi';

export const initialState = { ...English }
export const language = { English, Hindi }

const languageContext = createContext(initialState)

global.consts = initialState

export default languageContext;
import axios from "axios";
import { get } from "lodash";
import { call, put } from "redux-saga/effects";
//
import { getValue, setValue } from './common';
import asyncStoreConst from './asyncStoreConst';
// 
export const requestPending = type => `${type}_PENDING`;
export const requestSuccess = type => `${type}_SUCCESS`;
export const requestFail = type => `${type}_FAILURE`;

export const request = ({
    type,
    method,
    url,
    data,
    headers = {},
    success,
    fail,
    baseURL
}) =>
    function* apiRequest() {
        try {
            if (type) {
                yield put({
                    type: requestPending(type),
                    originalType: type
                });
            }

            const authToken = yield getValue(asyncStoreConst.ACCESS_TOKEN),
                sessionCode = yield getValue(asyncStoreConst.SESSION_CODE);

            // SET AXIOS DEFAULT VALUE
            axios.defaults.baseURL = baseURL;
            axios.defaults.headers.common = {
                "Cache-Control": "no-store",
                Pragma: "no-cache",
                "Content-Type": "application/json",
                "branchId": yield getValue(asyncStoreConst.BRANCH_ID)
            };
            axios.defaults.headers.common.Authorization = authToken ? `Bearer ${authToken}` : "";
            headers['sessioncode'] = sessionCode;
            // headers['sessioncode'] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjIsImlhdCI6MTYzODYxMDU3NH0.GtwWo4lVxLkTR4aUHoEtuw0akO7a8udbRBWzVufWA20";

            const res = yield call(axios.request, {
                url,
                method: method.toLowerCase(),
                headers: headers || {},
                data: data || {}
            });

            if (res.data.success) {
                if (success) success(res.data);
            }
            else { if (fail) fail(res.data); }

            if (type) {
                yield put({
                    type: res.data.success ? requestSuccess(type) : requestFail(type),
                    payload: res.data.data,
                    originalType: type
                });
            }
            return res.data;
        } catch (err) {
            const errRes = get(err, "data", err);
            // if (errRes.status === 401) {
            //     try {
            //         console.log('errRes.status :400')
            //         //   refreshToken()
            //     } catch (e) {
            //         console.log(e);
            //     }
            // }

            if (fail) fail(errRes);
            if (type) {
                yield put({
                    type: requestFail(type),
                    payload: errRes,
                    originalType: type
                });
            } else {
                throw err;
            }
        }
    };
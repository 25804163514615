// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.priceBox {
  flex-direction: column;
  padding-left: 10px;
}

.newPrice {
  font-family: "OpenSans-Regular";
  font-size: 0.8em;
  line-height: 1.2em;
  letter-spacing: 0.02em;
  color: #424242;
  white-space: nowrap;
}

.newPriceSup {
  font-family: "OpenSans-Regular";
  font-size: 0.6em;
  line-height: 1em;
  letter-spacing: 0.02em;
  color: #424242;
  display: flex;
  align-items: center;
}

.oldPrice {
  font-family: "OpenSans-Light";
  font-size: 0.8em;
  line-height: 1em;
  letter-spacing: 0.02em;
  color: #8a8a8a;
  text-decoration-line: line-through;
}

.oldPriceSup {
  font-family: "OpenSans-Light";
  font-size: 0.7em;
  line-height: 1em;
  letter-spacing: 0.02em;
  color: #8a8a8a;
  text-decoration-line: line-through;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/Price/Style.scss"],"names":[],"mappings":"AAGA;EACI,sBAAA;EACA,kBAAA;AAFJ;;AAIA;EACI,+BAAA;EACA,gBAAA;EACA,kBAAA;EACA,sBAAA;EACA,cAAA;EACA,mBAAA;AADJ;;AAGA;EACI,+BAAA;EACA,gBAAA;EACA,gBAAA;EACA,sBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;AAAJ;;AAEA;EACI,6BAAA;EACA,gBAAA;EACA,gBAAA;EACA,sBAAA;EACA,cAAA;EACA,kCAAA;AACJ;;AACA;EACI,6BAAA;EACA,gBAAA;EACA,gBAAA;EACA,sBAAA;EACA,cAAA;EACA,kCAAA;EACA,aAAA;EACA,mBAAA;AAEJ","sourcesContent":["@use \"sass:map\";\n@import \"../../../styles/colors.scss\";\n\n.priceBox {\n    flex-direction: column;\n    padding-left: 10px\n}\n.newPrice {\n    font-family: \"OpenSans-Regular\";\n    font-size: .8em;\n    line-height: 1.2em;\n    letter-spacing: 0.02em;\n    color: map.get($theme-colors,'BLACK_TEXT');\n    white-space: nowrap;\n}\n.newPriceSup {\n    font-family: \"OpenSans-Regular\";\n    font-size: .6em;\n    line-height: 1em;\n    letter-spacing: 0.02em;\n    color: map.get($theme-colors,'BLACK_TEXT');\n    display: flex;\n    align-items: center;\n}\n.oldPrice {\n    font-family: \"OpenSans-Light\";\n    font-size: .8em;\n    line-height: 1em;\n    letter-spacing: 0.02em;\n    color: map.get($theme-colors,'GRAY_DARK');\n    text-decoration-line: line-through;\n}\n.oldPriceSup {\n    font-family: \"OpenSans-Light\";\n    font-size: .7em;\n    line-height: 1em;\n    letter-spacing: 0.02em;\n    color: map.get($theme-colors,'GRAY_DARK');\n    text-decoration-line: line-through;\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

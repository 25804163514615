//
import { Segment, TransitionablePortal } from 'semantic-ui-react';
//
import { View, Button } from "..";

export default function AlertConfirmModal({ isOpen, label = "", cancelLabel = "Cancel", text = "", handleDone, handleCancel }) {
    return (
        <TransitionablePortal onClose={() => { }} open={isOpen}
            transition={{ animation: 'fly up', duration: 600 }}>
            <Segment
                className='custom-segment'
                style={{ left: '40%', position: 'fixed', top: '32%', zIndex: 1000 }}
            >
                <View className='alert-wrapper'>
                    <p>{text}</p>
                    <View className='footer-wrapper'>
                        <Button onPress={handleCancel} className="cancel-btn slide-button-gray-medium-down" label={cancelLabel} showIcon={false} />
                        <Button onPress={handleDone} className="submit-btn slide-button-alert-down" label={label} showIcon={false} />
                    </View>
                </View>
            </Segment>
        </TransitionablePortal>
    )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brand-name-logo-rope {
  height: 24px;
  width: 2px;
  background-color: #3a6b35;
  content: "";
  display: block;
  margin-left: 50%;
  margin-top: -18px;
}

.brand-name-logo {
  width: 230px;
  transition: all 0.2s linear;
  cursor: pointer;
  webkit-transform-origin: top center;
  transform-origin: top center;
  animation-name: hover-swing;
  animation-duration: 3s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}
.brand-name-logo img {
  width: 100%;
}
.brand-name-logo:hover {
  animation-name: none;
}

@keyframes hover-swing {
  20% {
    transform: rotate3d(0, 0, 1, 3deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -3.5deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 2.5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -2.5deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 0);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/HeaderLogo/HeaderLogo.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,UAAA;EACA,yBAAA;EACA,WAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,YAAA;EACA,2BAAA;EACA,eAAA;EAIA,mCAAA;EACA,4BAAA;EAEA,2BAAA;EAEA,sBAAA;EAEA,yBAAA;EACA,mCAAA;EACA,mBAAA;AAFJ;AAVI;EACI,WAAA;AAYR;AAAI;EACI,oBAAA;AAER;;AAEA;EACI;IAEI,kCAAA;EACN;EACE;IAEI,qCAAA;EACN;EACE;IAEI,oCAAA;EACN;EACE;IAEI,qCAAA;EACN;EACE;IAEI,+BAAA;EACN;AACF","sourcesContent":[".brand-name-logo-rope {\n    height: 24px;\n    width: 2px;\n    background-color: #3a6b35;\n    content: \"\";\n    display: block;\n    margin-left: 50%;\n    margin-top: -18px;\n}\n\n.brand-name-logo {\n    width: 230px;\n    transition: all 0.2s linear;\n    cursor: pointer;\n    img {\n        width: 100%;\n    }\n    webkit-transform-origin: top center;\n    transform-origin: top center;\n    -webkit-animation-name: hover-swing;\n    animation-name: hover-swing;\n    -webkit-animation-duration: 3s;\n    animation-duration: 3s;\n    -webkit-animation-fill-mode: both;\n    animation-fill-mode: both;\n    animation-iteration-count: infinite;\n    animation-delay: 2s;\n    &:hover{\n        animation-name: none;\n    }\n}\n\n@keyframes hover-swing {\n    20% {\n        -webkit-transform: rotate3d(0, 0, 1, 3deg);\n        transform: rotate3d(0, 0, 1, 3deg);\n    }\n    40% {\n        -webkit-transform: rotate3d(0, 0, 1, -3.5deg);\n        transform: rotate3d(0, 0, 1, -3.5deg);\n    }\n    60% {\n        -webkit-transform: rotate3d(0, 0, 1, 2.5deg);\n        transform: rotate3d(0, 0, 1, 2.5deg);\n    }\n    80% {\n        -webkit-transform: rotate3d(0, 0, 1, -2.5deg);\n        transform: rotate3d(0, 0, 1, -2.5deg);\n    }\n    100% {\n        -webkit-transform: rotate3d(0, 0, 1, 0);\n        transform: rotate3d(0, 0, 1, 0);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';
//
import { store, persistor } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { AppLoading } from './components/atoms';
//
import '../src/styles/commonStyle.scss';
import './index.css';

const App = React.lazy(() => import('./App'));


const farmerBuggyApp = ReactDOM.createRoot(document.getElementById('farmerbuggyapp'));
farmerBuggyApp.render(
  <Suspense fallback={<AppLoading />}>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <App />
          </Router>
        </PersistGate>
      </Provider >
    </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
//
import { View } from '../View';
import AppNameLogo from '../../../assets/images/logo-name.png'
import { TouchableOpacity } from '../TouchableOpacity';
import { Image } from '../Image';


export default function HeaderLogo() {

    const push = useNavigate(),
        { pathname } = useLocation();

    const handleRedirectTo = () => {
        if (pathname.indexOf('products') >= 0) push("/home")
        else push("/products")
    }

    return (
        <View className='brand-name-logo-wrapper'>
            <View className='brand-name-logo-rope' />
            <TouchableOpacity className={'brand-name-logo'} onPress={handleRedirectTo}>
                <Image source={AppNameLogo} />
            </TouchableOpacity>
        </View>
    )
}

import { handleActions } from "redux-actions";
//
import { initialState } from "./initialState";
import { constants } from "./constants";

export default handleActions(
    {
        [constants.REQUEST_FOR_BROWSER_PERMISSION]: (
            state,
        ) => ({
            ...state,
            requestPermission: true,
        }),
        [constants.SET_PERMISSION_STATUS]: (
            state,
            action
        ) => ({
            ...state,
            permissionStatus: action.payload,
        }),
        [constants.OPEN_PERMISSION_MODAL]: (
            state
        ) => {
            return {
                ...state,
                isOpenPermissionModal: true,
            }
        },
        [constants.CLOSE_PERMISSION_MODAL]: (
            state
        ) => ({
            ...state,
            isOpenPermissionModal: false,
        }),
        [constants.SET_CURRENT_LOCATION]: (
            state,
            action
        ) => {
            return {
                ...state,
                currentLocation: action.payload,
            }
        },
    },
    initialState()
);

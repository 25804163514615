import { call, put, takeLatest } from 'redux-saga/effects';
//
import { request, requestSuccess } from '../../../utils/fetch';
import { constants } from './constants';
import { config } from '../../../utils/apiConfig';

function* fetchAvilableBranch(action) {
  yield call(
    request({
      type: constants.FETCH_AVILABLE_BRANCH,
      baseURL: config.API_END_POINT,
      method: 'GET',
      url: `/branch/withLocation`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action,
  );
}

function* fetchBranchDeliveryArea(action) {
  yield call(
    request({
      type: constants.FETCH_BRANCH_DELIVERY_AREA,
      baseURL: config.API_END_POINT,
      method: 'GET',
      url: `/branch/deliveryArea`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action,
  );
}

function* fetchBranchList(action) {
  yield call(
    request({
      type: constants.FETCH_BRANCH_LIST,
      baseURL: config.API_END_POINT,
      method: 'GET',
      url: `/branch/list`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action,
  );
}

function* changeBranch(action) {
  yield call(
    request({
      type: constants.CHANGE_BRANCH,
      baseURL: config.API_END_POINT,
      method: 'PUT',
      url: `/branch/change`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
    }),
    action,
  );
}


function* setActiveBranch({ payload }) {
  yield put({
    type: requestSuccess(constants.SET_ACTIVE_BRANCH),
    payload: payload,
  });
}

export default function* rootSaga() {
  yield takeLatest(constants.FETCH_BRANCH_LIST, fetchBranchList);
  yield takeLatest(constants.FETCH_AVILABLE_BRANCH, fetchAvilableBranch);
  yield takeLatest(constants.FETCH_BRANCH_DELIVERY_AREA, fetchBranchDeliveryArea);
  yield takeLatest(constants.SET_ACTIVE_BRANCH, setActiveBranch);
  yield takeLatest(constants.CHANGE_BRANCH, changeBranch);
}

import { handleActions } from 'redux-actions';
//
import { initialState } from './initialState';
import { constants } from './constants';
import {
  requestSuccess,
  requestPending,
  requestFail,
} from '../../../utils/fetch';

export default handleActions(
  {

    [requestSuccess(constants.CHANGE_BRANCH)]: (state) => ({
      ...state,
      changeBranchLoading: false,
      changeBranchFailure: false,
      changeBranchSuccess: true,
    }),
    [requestPending(constants.CHANGE_BRANCH)]: state => ({
      ...state,
      changeBranchLoading: true,
      changeBranchFailure: false,
      changeBranchSuccess: false,
    }),
    [requestFail(constants.CHANGE_BRANCH)]: state => ({
      ...state,
      changeBranchLoading: false,
      changeBranchFailure: true,
      changeBranchSuccess: false,
    }),
    [requestSuccess(constants.FETCH_AVILABLE_BRANCH)]: (state, action) => ({
      ...state,
      avilableBranch: action.payload || {},
      fetchAvilableBranchLoading: false,
      fetchAvilableBranchFailure: false,
      fetchAvilableBranchSuccess: true,
    }),
    [requestPending(constants.FETCH_AVILABLE_BRANCH)]: state => ({
      ...state,
      fetchAvilableBranchLoading: true,
      fetchAvilableBranchFailure: false,
      fetchAvilableBranchSuccess: false,
    }),
    [requestFail(constants.FETCH_AVILABLE_BRANCH)]: state => ({
      ...state,
      fetchAvilableBranchLoading: true,
      fetchAvilableBranchFailure: false,
      fetchAvilableBranchSuccess: false,
    }),
    [requestSuccess(constants.FETCH_BRANCH_DELIVERY_AREA)]: (
      state,
      action,
    ) => ({
      ...state,
      branchDeliveryArea: action.payload || {},
      fetchBranchDeliveryAreaLoading: false,
      fetchBranchDeliveryAreaFailure: false,
      fetchBranchDeliveryAreaSuccess: true,
    }),
    [requestPending(constants.FETCH_BRANCH_DELIVERY_AREA)]: state => ({
      ...state,
      fetchBranchDeliveryAreaLoading: true,
      fetchBranchDeliveryAreaFailure: false,
      fetchBranchDeliveryAreaSuccess: false,
    }),
    [requestFail(constants.FETCH_BRANCH_DELIVERY_AREA)]: state => ({
      ...state,
      fetchBranchDeliveryAreaLoading: true,
      fetchBranchDeliveryAreaFailure: false,
      fetchBranchDeliveryAreaSuccess: false,
    }),
    [requestSuccess(constants.FETCH_BRANCH_LIST)]: (
      state,
      action,
    ) => ({
      ...state,
      branchList: action.payload || {},
      fetchBranchListLoading: false,
      fetchBranchListFailure: false,
      fetchBranchListSuccess: true,
    }),
    [requestPending(constants.FETCH_BRANCH_LIST)]: state => ({
      ...state,
      fetchBranchListLoading: true,
      fetchBranchListFailure: false,
      fetchBranchListSuccess: false,
    }),
    [requestFail(constants.FETCH_BRANCH_LIST)]: state => ({
      ...state,
      fetchBranchListLoading: true,
      fetchBranchListFailure: false,
      fetchBranchListSuccess: false,
    }),
    [requestSuccess(constants.SET_ACTIVE_BRANCH)]: (state, action) => {
      console.log("action.payload  ...", action.payload)
      return ({
        ...state,
        activeBranch: action.payload || {}
      })
    },
  },
  initialState(),
);

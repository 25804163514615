// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appLoadingContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.appLoadingContainer .imageWrapper {
  height: 200px;
}
.appLoadingContainer .imageWrapper img {
  height: 100%;
  width: auto;
}

.my-account .appLoadingContainer {
  height: 70vh;
  width: auto;
}

.add-edit-address-wrapper .appLoadingContainer {
  height: 50vh;
  width: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/AppLoading/Style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AAAI;EACI,aAAA;AAER;AADQ;EACI,YAAA;EACA,WAAA;AAGZ;;AAGI;EACI,YAAA;EACA,WAAA;AAAR;;AAKI;EACI,YAAA;EACA,WAAA;AAFR","sourcesContent":[".appLoadingContainer {\n    height: 100vh;\n    width: 100vw;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    .imageWrapper {\n        height: 200px;\n        img {\n            height: 100%;\n            width: auto;\n        }\n    }\n}\n\n.my-account {\n    .appLoadingContainer {\n        height: 70vh;\n        width: auto;\n    }\n}\n\n.add-edit-address-wrapper {\n    .appLoadingContainer {\n        height: 50vh;\n        width: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import "./Style.scss";
import { Icon } from "../Icon";
import { useDispatch, useSelector } from "react-redux";
import { closePermissionModal, setCurrentLocation, setPermissionStatus, openPermissionModal } from "../../../redux/modules/locationPermission/action";
import { FindLoactionLoader } from "../AppLoading";
import { ActivityIndicator } from "../ActivityIndicator";
import { PRIMARY } from "../../../styles/colors";

export const getCurrentPostion = (onSuccess, onFail = () => { }) => {
  if ("geolocation" in navigator) {
    const options = {
      enableHighAccuracy: true,
      timeout: 10000,
    };
    navigator.geolocation.getCurrentPosition(onSuccess, onFail, options);
  } else {
    onFail()
  }
}

export const isWebHavePermission = (callBack) => {
  if ("geolocation" in navigator) {
    navigator.permissions.query({ name: "geolocation" }).then((permistion) => {
      if (permistion.state !== "granted") {
        callBack(false)
      } else {
        callBack(true)
      }
    })
  } else {
    callBack(false)
  }
}

const LocationPermissionModal = ({ defaultCheck = true }) => {

  const dispatch = useDispatch(),
    { permissionStatus, isOpenPermissionModal } = useSelector(store => store.locationPermission),
    [loading, setLoading] = useState();

  useEffect(() => {

    const callBack = (hasPermission) => {
      if (hasPermission) {
        handleAskBrowserPermission()
      } else {
        !permissionStatus && dispatch(openPermissionModal())
      }
    }

    defaultCheck && isWebHavePermission(callBack);

    return () => {
      dispatch(closePermissionModal());
      setLoading(false)
    }
  }, [])

  const closeModal = () => {
    dispatch(closePermissionModal());
  };

  const handleAskBrowserPermission = () => {
    setLoading(true)
    const onSuccess = (position) => {

      dispatch(setPermissionStatus("granted"))
      setLoading(false)
      closeModal();
      dispatch(setCurrentLocation(position.coords))
    }
    const onFail = (error) => {
      setLoading(false)
      closeModal()
      dispatch(setPermissionStatus(error?.code))
    }
    getCurrentPostion(onSuccess, onFail)
  }

  return (
    <>
      {isOpenPermissionModal && (
        <div className="permission-modal">
          <div className="modal-content">
            <h4 className="headingstyle">Welcome To <span>Farmer</span><span> Buggy</span></h4>
            <div style={{ margin: "15px 0px", display: "flex" }}>
              {loading
                ? <ActivityIndicator color={'green'} size='big' />
                : <Icon name="LocationPin" size="wl" />
              }
              <div className="please-provide-your">
                Please provide your delivery location to see products at nearby
                store
              </div>
            </div>
            {
              loading
                ? <></>
                : <div className="action-wrapper">
                  <button
                    className="btn location-box mask-button"
                    onClick={handleAskBrowserPermission}
                  >
                    Detect my location
                  </button>
                  <div className="oval-container">
                    <div className="oval">
                      <span className="separator-text">
                        <div className="or">OR</div>
                      </span>
                    </div>
                  </div>
                  <div className="modal-right__input-wrapper">
                    <div className="display--table full-width">
                      <div className="display--table-cell full-width">
                        <input onClick={closeModal}
                          type="text"
                          name="select-locality"
                          placeholder="Search delivery location"
                          autocomplete="off"
                          className="text-box"
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>
      )}
    </>
  );
};


export default LocationPermissionModal;

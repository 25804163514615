import { handleActions } from "redux-actions";
import { initialState } from "./initialState";
import { constants } from "./constants";
import { requestSuccess, requestPending, requestFail } from "../../../utils/fetch";

export default handleActions(
    {
        [requestSuccess(constants.FETCH_PAYMENT_DETAIL)]: (
            state,
            action
        ) => ({
            ...state,
            paymentDetail: action.payload || {},
            fetchpaymentDetailLoading: false,
            fetchpaymentDetailFailure: false,
            fetchpaymentDetailSuccess: true,
        }),
        [requestPending(constants.FETCH_PAYMENT_DETAIL)]: (
            state
        ) => ({
            ...state,
            fetchpaymentDetailLoading: true,
            fetchpaymentDetailFailure: false,
            fetchpaymentDetailSuccess: false,
        }),
        [requestFail(constants.FETCH_PAYMENT_DETAIL)]: (
            state
        ) => ({
            ...state,
            fetchpaymentDetailLoading: true,
            fetchpaymentDetailFailure: false,
            fetchpaymentDetailSuccess: false,
        }),
    },
    initialState()
);

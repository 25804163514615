import { handleActions } from "redux-actions";
//
import { initialState } from "./initialState";
import { constants } from "./constants";
import { requestSuccess, requestPending, requestFail } from "../../../utils/fetch";

export default handleActions(
    {
        [requestSuccess(constants.FETCH_DELIVERY_AREA_LIST)]: (
            state,
            action
        ) => ({
            ...state,
            deliveryAreaList: action.payload || [],
            fetchDeliveryAreaListLoading: false,
            fetchDeliveryAreaListFailure: false,
            fetchDeliveryAreaListSuccess: true,
        }),
        [requestPending(constants.FETCH_DELIVERY_AREA_LIST)]: (
            state
        ) => ({
            ...state,
            fetchDeliveryAreaListLoading: true,
            fetchDeliveryAreaListFailure: false,
            fetchDeliveryAreaListSuccess: false,
        }),
        [requestFail(constants.FETCH_DELIVERY_AREA_LIST)]: (
            state
        ) => ({
            ...state,
            fetchDeliveryAreaListLoading: true,
            fetchDeliveryAreaListFailure: false,
            fetchDeliveryAreaListSuccess: false,
        }),
    },
    initialState()
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-buttom {
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 45px;
}
.custom-buttom .label {
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.custom-buttom:hover .tintColor {
  transition: ease-in-out 0.3s;
  filter: brightness(0) invert(1) !important;
}

.custom-buttom.loader {
  justify-content: center !important;
}

.custom-buttom.disable:hover {
  cursor: no-drop;
}
.custom-buttom.disable:hover .tintColor {
  filter: brightness(1) !important;
  box-shadow: inset 0px 50px 0 0 #e5e3df !important;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/Button/Style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,mBAAA;EACA,YAAA;AACJ;AAAI;EACI,yBAAA;EACA,qBAAA;AAER;;AAEI;EACI,4BAAA;EACA,0CAAA;AACR;;AAEA;EACI,kCAAA;AACJ;;AAEA;EACI,eAAA;AACJ;AAAI;EACI,gCAAA;EACA,iDAAA;AAER","sourcesContent":[".custom-buttom {\n    display: flex;\n    cursor: pointer;\n    align-items: center;\n    height: 45px;\n    .label {\n        text-transform: uppercase;\n        letter-spacing: .5px;\n    }\n}\n.custom-buttom:hover {\n    .tintColor {\n        transition: ease-in-out 0.3s;\n        filter: brightness(0) invert(1) !important;\n    }\n}\n.custom-buttom.loader {\n    justify-content: center !important;\n}\n\n.custom-buttom.disable:hover {\n    cursor: no-drop;\n    .tintColor {\n        filter: brightness(1) !important;\n        box-shadow: inset 0px 50px 0 0 #e5e3df !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
// SAGA
import modalSaga from "./modal/saga";
import authSaga from "./auth/saga";
import categorySaga from "./category/saga";
import productSaga from "./product/saga";
import cartSaga from "./cart/saga";
import addressSaga from "./address/saga";
import orderSaga from "./order/saga";
import branchSaga from "./branch/saga";
import branchStoreSaga from "./branchStore/saga";
import paymentSaga from "./payment/saga";
// REDUCERS
import paymentReducer from "./payment/reducer";
import modalReducer from "./modal/reducer";
import authReducer from "./auth/reducer";
import categoryReducer from "./category/reducer";
import productReducer from "./product/reducer";
import cartReducer from "./cart/reducer";
import addressReducer from "./address/reducer";
import orderReducer from "./order/reducer";
import branchReducer from "./branch/reducer";
import branchStoreReducer from "./branchStore/reducer";
import locationPermissionReducer from "./locationPermission/reducer";
import { setValue } from "../../utils/common";
import asyncStoreConst from "../../utils/asyncStoreConst";

const appReducer = combineReducers({
    payment: paymentReducer,
    modal: modalReducer,
    auth: authReducer,
    category: categoryReducer,
    product: productReducer,
    cart: cartReducer,
    address: addressReducer,
    order: orderReducer,
    branch: branchReducer,
    branchStore: branchStoreReducer,
    locationPermission: locationPermissionReducer,

});

export const reducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        setValue(asyncStoreConst.SESSION_CODE, "")
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

export const rootSaga = function* () {
    yield all([
        paymentSaga(),
        authSaga(),
        modalSaga(),
        categorySaga(),
        productSaga(),
        cartSaga(),
        addressSaga(),
        orderSaga(),
        branchSaga(),
        branchStoreSaga()
    ]);
};
